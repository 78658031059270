import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const NavMenuLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: #1976d2;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: #1976d2;
  }
  width: 100%;
`;

const TopBarMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <NavMenuLink to="/blog/reducing-on-call-load">
          <MenuItem>
            <Typography>5 tips for reducing on-call load</Typography>
          </MenuItem>
        </NavMenuLink>
        <NavMenuLink to={{ pathname: "/", search: "loc=measure" }}>
          <MenuItem>
            <Typography>Try now</Typography>
          </MenuItem>
        </NavMenuLink>
      </Menu>
    </div>
  );
};

export default TopBarMenu;
