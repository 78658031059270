import * as React from "react";
import { Typography } from "@mui/material";

type BlogPostSectionHeadingProps = {
  children: React.ReactNode;
};

const BlogPostSectionHeading = ({ children }: BlogPostSectionHeadingProps) => (
  <Typography variant="h5" css={{ marginBottom: "15px", marginTop: "15px" }}>
    {children}
  </Typography>
);

export default BlogPostSectionHeading;
