import * as React from "react";
import styled from "@emotion/styled";
import { AppBar, Button, Grid, Toolbar, Typography } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
// @ts-ignore
import logo from "./favicon-32x32.png";
import { Link } from "react-router-dom";
import TopBarMenu from "./TopBarMenu";

const TopBarSection = styled(AppBar)`
  padding: 14px;
  background-color: white;
  color: black;
`;

const TopToolbar = styled(Toolbar)`
  min-height: 0 !important;
`;

const Logo = styled.img`
  margin-right: 10px;
  vertical-align: top;
`;

const NavLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: #1976d2;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: #1976d2;
  }
  vertical-align: middle;
  margin-right: 20px;
`;

const TopBar = () => {
  return (
    <TopBarSection position="fixed">
      <TopToolbar disableGutters>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xl={6} lg={8} md={10} xs={10}>
            <Logo src={logo} width="32" height="32" />
            <NavLink to="/">
              <Typography variant="h6">OnCalm</Typography>
            </NavLink>

            <NavLink
              to={{ pathname: "/", search: "loc=measure" }}
              css={{ float: "right", marginRight: "0" }}
            >
              <Button
                color="primary"
                variant="contained"
                size="small"
                sx={{ display: { xs: "none", sm: "inline-block" } }}
                css={{ marginLeft: "20px" }}
              >
                Try now
              </Button>
            </NavLink>

            <NavLink to="/blog/reducing-on-call-load" css={{ float: "right" }}>
              <Typography
                variant="h6"
                sx={{ display: { xs: "none", sm: "inline-block" } }}
              >
                <ArticleOutlinedIcon
                  css={{ marginRight: "5px", verticalAlign: "text-top" }}
                />
                5 tips for reducing on-call load
              </Typography>
            </NavLink>
          </Grid>
          <Grid
            item
            sx={{ display: { xs: "inline-block", sm: "none" } }}
            xs={2}
          >
            <TopBarMenu />
          </Grid>
        </Grid>
      </TopToolbar>
    </TopBarSection>
  );
};

export default TopBar;
