import { Alert } from "./Alert";

const calcDeltaInMinutes = (a: Alert, b: Alert): number =>
  Math.abs(a.createdOn.diff(b.createdOn).as("minutes"));

const anotherAlertWithinMinutes = (
  uniqueAlerts: Alert[],
  alertToCheck: Alert,
  deltaInMinutes: number
): boolean =>
  uniqueAlerts.some(
    (alert) => calcDeltaInMinutes(alert, alertToCheck) <= deltaInMinutes
  );

// Calculate the disturbances - alerts that are more than X minutes apart
const calculateUniqueAlerts = (
  alerts: Alert[],
  deltaInMinutes: number
): Alert[] => {
  const uniqueAlerts = [];

  const sortedAlerts = alerts.sort(
    (a, b) => a.createdOn.toMillis() - b.createdOn.toMillis()
  );

  sortedAlerts.forEach((alert) => {
    const isUnique = !anotherAlertWithinMinutes(
      uniqueAlerts,
      alert,
      deltaInMinutes
    );

    if (isUnique) {
      uniqueAlerts.push(alert);
    }
  });

  return uniqueAlerts;
};

export { calculateUniqueAlerts };
