import { DateTime } from "luxon";
import AlertTiming from "./AlertTiming";

const calculateAlertTiming = (
  createdOn: DateTime,
  zoneName: string
): AlertTiming => {
  const createdOnAtZone = createdOn.setZone(zoneName);

  const hour = createdOnAtZone.hour;
  const dayOfWeek = createdOnAtZone.weekday; // 1 is Monday and 7 is Sunday
  const saturday = 6;
  const sunday = 7;

  const nightTimeHourStart = 22;
  const nightTimeHourEnd = 8;

  const offHoursHourStart = 17;

  if (hour >= nightTimeHourStart || hour < nightTimeHourEnd) {
    return AlertTiming.NightTime;
  } else if (hour >= offHoursHourStart && hour < nightTimeHourStart) {
    return AlertTiming.OffHours;
  } else if (dayOfWeek === saturday || dayOfWeek === sunday) {
    return AlertTiming.Weekend;
  } else {
    return AlertTiming.WorkHours;
  }
};

export { calculateAlertTiming };
