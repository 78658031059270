import * as React from "react";
import { Typography } from "@mui/material";

type BlogPostTitleProps = {
  children: React.ReactNode;
};

const BlogPostTitle = ({ children }: BlogPostTitleProps) => (
  <Typography
    variant="h3"
    css={{
      marginBottom: "25px",
    }}
  >
    {children}
  </Typography>
);

export default BlogPostTitle;
