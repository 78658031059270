import { Alert } from "./Alert";
import { calculateAlertTiming } from "./AlertTimingCalculator";
import { AlertResults } from "./AlertResults";
import { calculateUniqueAlerts } from "./UniqueAlertCalculator";

const processTimingForAlerts = (alerts: Alert[], zoneName: string): Alert[] =>
  alerts.map((alert) => {
    alert.timing = calculateAlertTiming(alert.createdOn, zoneName);

    return alert;
  });

const processAlerts = (
  alerts: Alert[],
  zoneName: string,
  uniqueDeltaMinutes: number
): AlertResults => {
  const allAlerts = processTimingForAlerts(alerts, zoneName);
  const uniqueAlerts = calculateUniqueAlerts(allAlerts, uniqueDeltaMinutes);

  return {
    allAlerts: allAlerts,
    uniqueAlerts: uniqueAlerts,
  } as AlertResults;
};

export { processAlerts, processTimingForAlerts };
