import * as React from "react";
import { Grid, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";

const FooterLink = styled(Typography)`
  display: inline-block;
  padding: 10px;
`;

const FooterGrid = styled(Grid)`
  text-align: center;
`;

const Footer = () => {
  return (
    <footer>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <FooterGrid item xs={12}>
          <FooterLink>
            <Link href="/privacy.html" data-testid="footer-link-privacy">
              Privacy Policy
            </Link>
          </FooterLink>
          <FooterLink>
            <Link href="/terms.html" data-testid="footer-link-terms">
              Terms of Service
            </Link>
          </FooterLink>
        </FooterGrid>
      </Grid>
    </footer>
  );
};

export default Footer;
