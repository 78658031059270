import { api } from "@pagerduty/pdjs";
import { Alert } from "../alert/Alert";
import { DateTime } from "luxon";

export type PagerDutyService = {
  id: string;
  name: string;
};

export const fetchServices = async (
  apiKey: string
): Promise<PagerDutyService[]> => {
  const pd = api({ token: apiKey });

  return pd.all("/services").then((resp) => {
    const services = resp.data.flatMap((elem) => elem.services);

    const pagerDutyServices: PagerDutyService[] = services.map((service) => ({
      id: service.id,
      name: service.name,
    }));

    return Promise.resolve(pagerDutyServices);
  });
};

export const fetchAlerts = async (
  service: PagerDutyService,
  from: DateTime,
  to: DateTime,
  apiKey: string
): Promise<Alert[]> => {
  const pd = api({ token: apiKey });

  const sinceString = from.toISO();
  const untilString = to.toISO();

  return pd
    .all(`/incidents?since=${sinceString}&until=${untilString}`)
    .then((resp) => {
      const incidents = resp.data.flatMap((elem) => elem.incidents);

      const alerts: Alert[] = incidents.map((incident) => ({
        id: incident.id,
        description: incident.summary,
        createdOn: DateTime.fromISO(incident.created_at),
      }));

      return Promise.resolve(alerts);
    });
};
