import * as React from "react";
import { AlertResults } from "./alert/AlertResults";
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import AlertTiming from "./alert/AlertTiming";
import AlertCountStatus from "./AlertCountStatus";
import styled from "@emotion/styled";

interface AlertResultsSummaryProps {
  alertResults: AlertResults;
  handleAlertTimingFilters: (newTimingFilters: AlertTiming[]) => void;
}

interface AlertTableRow {
  label: string;
  labelTooltip: string;
  alertTimingFilters?: AlertTiming[];
  uniqueCount: number;
  totalCount: number;
  goodThreshold?: number;
  warningThreshold?: number;
  goodTooltip?: string;
  warningTooltip?: string;
  unsustainableTooltip?: string;
  testIdPrefix: string;
}

const AlertTableHeadCell = styled(TableCell)`
  background-color: lightgrey;
`;

const LabelLink = styled(Link)({
  cursor: "pointer",
});

const AlertResultsTable = ({
  alertResults,
  handleAlertTimingFilters,
}: AlertResultsSummaryProps) => {
  const tableRows = [
    {
      label: "Work hours",
      labelTooltip: "Between 8am-5pm Monday-Friday",
      uniqueCount: alertResults.uniqueAlerts.filter(
        (alert) => alert.timing === AlertTiming.WorkHours
      ).length,
      totalCount: alertResults.allAlerts.filter(
        (alert) => alert.timing === AlertTiming.WorkHours
      ).length,
      alertTimingFilters: [AlertTiming.WorkHours],
      goodThreshold: 2,
      warningThreshold: 5,
      goodTooltip: "2 or fewer work-hours alerts is great!",
      warningTooltip:
        "Getting close to unsustainable levels of work-hours alerting",
      unsustainableTooltip:
        "This level of work-hours alerting is not sustainable",
      testIdPrefix: "work-hours",
    } as AlertTableRow,
    {
      label: "Off-hours",
      labelTooltip:
        "Between 5pm-10pm Monday-Friday and all day Saturday and Sunday",
      uniqueCount: alertResults.uniqueAlerts.filter(
        (alert) =>
          alert.timing === AlertTiming.OffHours ||
          alert.timing === AlertTiming.Weekend
      ).length,
      totalCount: alertResults.allAlerts.filter(
        (alert) =>
          alert.timing === AlertTiming.OffHours ||
          alert.timing === AlertTiming.Weekend
      ).length,
      alertTimingFilters: [AlertTiming.OffHours, AlertTiming.Weekend],
      goodThreshold: 0,
      warningThreshold: 2,
      goodTooltip: "No off-hours alerts is great!",
      warningTooltip:
        "Getting close to unsustainable levels of off-hours alerting",
      unsustainableTooltip:
        "This level of off-hours alerting is not sustainable",
      testIdPrefix: "off-hours",
    } as AlertTableRow,
    {
      label: "Night-time",
      labelTooltip: "Between 10pm-8am every day",
      uniqueCount: alertResults.uniqueAlerts.filter(
        (alert) => alert.timing === AlertTiming.NightTime
      ).length,
      totalCount: alertResults.allAlerts.filter(
        (alert) => alert.timing === AlertTiming.NightTime
      ).length,
      alertTimingFilters: [AlertTiming.NightTime],
      goodThreshold: 0,
      warningThreshold: 1,
      goodTooltip: "No night-time alerts is great!",
      warningTooltip:
        "Getting close to unsustainable levels of night-time alerting",
      unsustainableTooltip:
        "This level of night-time alerting is not sustainable and will burn out your engineers",
      testIdPrefix: "night-time",
    } as AlertTableRow,
    {
      label: "Total",
      labelTooltip: "All pages",
      alertTimingFilters: [
        AlertTiming.WorkHours,
        AlertTiming.OffHours,
        AlertTiming.Weekend,
        AlertTiming.NightTime,
      ],
      uniqueCount: alertResults.uniqueAlerts.length,
      totalCount: alertResults.allAlerts.length,
      testIdPrefix: "total",
    } as AlertTableRow,
  ];

  const handleFilterClick = (alertTimings?: AlertTiming[]) => () =>
    handleAlertTimingFilters(alertTimings);

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <AlertTableHeadCell>Alert timing</AlertTableHeadCell>
            <AlertTableHeadCell align="center">
              <Tooltip
                title="A unique disruption is a page that is at least 15 minutes apart from another page"
                placement="top"
              >
                <span>Unique disruptions</span>
              </Tooltip>
            </AlertTableHeadCell>
            <AlertTableHeadCell align="center">All alerts</AlertTableHeadCell>
            <AlertTableHeadCell align="center">Status</AlertTableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((tableRow, idx) => (
            <TableRow key={`alert-results-table-${idx}`}>
              <TableCell data-testid={`${tableRow.testIdPrefix}-label`}>
                <Tooltip title={tableRow.labelTooltip} placement="left">
                  <LabelLink
                    onClick={handleFilterClick(tableRow.alertTimingFilters)}
                  >
                    {tableRow.label}
                  </LabelLink>
                </Tooltip>
              </TableCell>
              <TableCell
                align="center"
                data-testid={`${tableRow.testIdPrefix}-unique-count`}
              >
                {tableRow.uniqueCount}
              </TableCell>
              <TableCell
                align="center"
                data-testid={`${tableRow.testIdPrefix}-total-count`}
              >
                {tableRow.totalCount}
              </TableCell>
              <TableCell
                align="center"
                data-testid={`${tableRow.testIdPrefix}-status`}
              >
                {tableRow.goodThreshold >= 0 &&
                  tableRow.warningThreshold >= 0 && (
                    <AlertCountStatus
                      count={tableRow.uniqueCount}
                      goodThreshold={tableRow.goodThreshold}
                      warningThreshold={tableRow.warningThreshold}
                      goodTooltip={tableRow.goodTooltip}
                      warningTooltip={tableRow.warningTooltip}
                      unsustainableTooltip={tableRow.unsustainableTooltip}
                    />
                  )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AlertResultsTable;
