import * as React from "react";
import { useState } from "react";
import CsvUploadForm from "./CsvUploadForm";
import {
  Grid,
  LinearProgress,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import SyncAltOutlinedIcon from "@mui/icons-material/SyncAltOutlined";
import { parseCsv } from "./csv/CsvAlertParser";
import { SystemZone } from "luxon";
import { processAlerts } from "./alert/AlertTimingProcessor";
import { AlertResults } from "./alert/AlertResults";
import AlertResultsTable from "./AlertResultsTable";
import AlertDetailsTable from "./AlertDetails/AlertDetailsTable";
import AlertTiming from "./alert/AlertTiming";
import {
  sendApiProcessEvent,
  sendCsvProcessError,
  sendCsvProcessEvent,
} from "./telemetry/HoneycombService";
import PagerDutyApiView from "./PagerDutyView/PagerDutyApiView";
import { Alert } from "./alert/Alert";
import { fetchImpactAnalysisMessage } from "./openai/ImpactAnalysisService";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";

type AlertViewProps = {
  zoneName?: string;
};

const AlertViewGrid = styled(Grid)`
  background-color: #1976d2;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const AlertViewSection = styled(Paper)`
  margin-bottom: 20px;
`;

const UploadSection = styled(Paper)`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
`;

const UploadFormSection = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 15px;
`;

const AlertsDisplaySection = styled.div`
  margin-top: 20px;
`;

const SectionTitle = styled(Typography)`
  padding-top: 15px;
  padding-left: 5px;
  margin-left: 10px;
  margin-bottom: 15px;
`;

const SectionTextBody = styled(Typography)`
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 15px;
`;

const SectionLinearProgress = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 35px;
`;

const SectionDescription = styled(Typography)`
  margin-left: 15px;
`;

const InvalidAlertsMessage = styled.div`
  text-align: center;
`;

const TabsSection = styled(Tabs)`
  margin-left: 15px;
  margin-bottom: 20px;
`;

const MethodTab = styled(Tab)`
  min-height: 50px;
`;

const AlertView = ({ zoneName }: AlertViewProps) => {
  const [csvFileUploaded, setCsvFileUploaded] = useState<boolean>(false);
  const [csvFileName, setCsvFileName] = useState<string>(null);
  const [alertResults, setAlertResults] = useState<AlertResults>(null);
  const [chatGptResponse, setChatGptResponse] = useState<string>("");
  const [chatGptLoading, setChatGptLoading] = useState<boolean>(false);
  const [alertTimingFilters, setAlertTimingFilters] =
    useState<AlertTiming[]>(null);
  const [currentTab, setCurrentTab] = React.useState<string>("csv");

  const processAlertsForChatGpt = async (alertResults: AlertResults) => {
    const daytimeCount = alertResults.allAlerts.filter(
      (alert) => alert.timing !== AlertTiming.NightTime
    ).length;
    const nighttimeCount = alertResults.allAlerts.filter(
      (alert) => alert.timing === AlertTiming.NightTime
    ).length;

    setChatGptLoading(true);
    const message = await fetchImpactAnalysisMessage(
      daytimeCount,
      nighttimeCount
    );
    setChatGptLoading(false);

    setChatGptResponse(message);
  };

  const onFileUpload = (newFile: File) => {
    if (newFile) {
      setCsvFileUploaded(true);
      setCsvFileName(newFile.name);

      try {
        newFile.text().then((alertCsvFileText) => {
          const alerts = parseCsv(alertCsvFileText);

          const systemZoneName = new SystemZone().name;
          const uniqueDeltaMinutes = 15;
          const zoneNameToUse = zoneName || systemZoneName;

          const alertResults = processAlerts(
            alerts,
            zoneNameToUse,
            uniqueDeltaMinutes
          );

          sendCsvProcessEvent(alertResults.allAlerts.length);

          setAlertResults(alertResults);

          processAlertsForChatGpt(alertResults);
        });
      } catch (e) {
        sendCsvProcessError(e.message);
      }
    }
  };

  const onApiAlertsLoaded = (alerts: Alert[]) => {
    const systemZoneName = new SystemZone().name;
    const uniqueDeltaMinutes = 15;
    const zoneNameToUse = zoneName || systemZoneName;

    const alertResults = processAlerts(
      alerts,
      zoneNameToUse,
      uniqueDeltaMinutes
    );

    sendApiProcessEvent(alertResults.allAlerts.length);

    setAlertResults(alertResults);

    processAlertsForChatGpt(alertResults);
  };

  const handleAlertTimingFilters = (newTimingFilters: AlertTiming[]): void => {
    setAlertTimingFilters(newTimingFilters);
  };

  const isCsvTab = !currentTab || currentTab === "csv";
  const isApiTab = currentTab === "api";

  const handleTabChange = (_, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <AlertViewGrid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item md={6} xs={12}>
        <Stack>
          <UploadSection elevation={3} id="measure">
            <SectionTitle variant="h4">
              Analyze your on-call load with OnCalm
            </SectionTitle>

            <TabsSection value={currentTab} onChange={handleTabChange}>
              <MethodTab
                value="csv"
                label={<Typography variant="h6">CSV</Typography>}
                icon={<UploadFileOutlinedIcon />}
                iconPosition="start"
              />
              <MethodTab
                value="api"
                label={
                  <Typography variant="h6" data-testid="method-tab-api">
                    API
                  </Typography>
                }
                icon={<SyncAltOutlinedIcon />}
                iconPosition="start"
              />
            </TabsSection>

            {isCsvTab && (
              <div>
                <SectionDescription>
                  Upload a <Link href="#paging-supported">CSV file</Link> of the
                  alerts/pages from your team's last on-call rotation:
                </SectionDescription>
                <UploadFormSection>
                  <CsvUploadForm onFileUpload={onFileUpload} />
                </UploadFormSection>
              </div>
            )}
            {isCsvTab &&
              csvFileUploaded &&
              alertResults &&
              alertResults.allAlerts.length === 0 && (
                <InvalidAlertsMessage>
                  <Typography data-testid="no-alerts-found-message">
                    No alerts found in file {csvFileName} is it one of OnCalm's
                    supported file formats?
                  </Typography>
                </InvalidAlertsMessage>
              )}

            {isApiTab && (
              <div>
                <SectionDescription>
                  Load alerts from the PagerDuty API, start by entering your
                  PagerDuty API key to find your team's service:
                </SectionDescription>
                <PagerDutyApiView onAlertsLoaded={onApiAlertsLoaded} />
              </div>
            )}
          </UploadSection>
          {alertResults && (
            <div>
              {(chatGptResponse || chatGptLoading) && (
                <AlertViewSection elevation={3}>
                  <AlertsDisplaySection>
                    <SectionTitle variant="h5">
                      On-call analysis from ChatGPT
                    </SectionTitle>

                    {chatGptResponse && (
                      <SectionTextBody sx={{ fontFamily: "Monospace" }}>
                        {chatGptResponse}
                      </SectionTextBody>
                    )}
                    {chatGptLoading && (
                      <SectionLinearProgress>
                        <LinearProgress variant="indeterminate" />
                      </SectionLinearProgress>
                    )}
                  </AlertsDisplaySection>
                </AlertViewSection>
              )}

              <AlertViewSection elevation={3}>
                <AlertsDisplaySection>
                  <SectionTitle variant="h5">On-call details</SectionTitle>

                  <AlertResultsTable
                    alertResults={alertResults}
                    handleAlertTimingFilters={handleAlertTimingFilters}
                  />
                </AlertsDisplaySection>
              </AlertViewSection>

              <AlertViewSection elevation={3}>
                <AlertsDisplaySection>
                  <AlertDetailsTable
                    alerts={alertResults.allAlerts}
                    alertTimingFilters={alertTimingFilters}
                  />
                </AlertsDisplaySection>
              </AlertViewSection>
            </div>
          )}
        </Stack>
      </Grid>
    </AlertViewGrid>
  );
};

export default AlertView;
