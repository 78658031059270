import * as React from "react";
import { Grid, Typography } from "@mui/material";
import HomePageStep from "./HomePageStep";
import styled from "@emotion/styled";

const HomePageGrid = styled(Grid)`
  padding-left: 10px;
  padding-right: 10px;

  padding-bottom: 30px;
  background-color: #ededed;
`;

const HomePageSteps = () => {
  return (
    <div>
      <HomePageGrid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={3} xs={12}>
          <HomePageStep
            title="Measure on-call sustainability"
            body="OnCalm breaks down your pages into different buckets (work hours, night-time, etc.) and calculates their sustainability"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <HomePageStep
            title="Calculate unique distributions"
            body="Pages every hour are more disruptive than a page storm during an incident, OnCalm calculates and uses unique disruptions for measuring on-call load"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <HomePageStep
            title="Get started easily"
            body="Works with data from your existing paging system (such as PagerDuty), simply download the alert CSV for the last on-call rotation and upload it below or connect to the PagerDuty API"
          />
        </Grid>
      </HomePageGrid>
    </div>
  );
};

export default HomePageSteps;
