import * as React from "react";
import { createRoot } from "react-dom/client";
import TopBar from "./TopBar";
import styled from "@emotion/styled";
import { createTheme, ThemeProvider } from "@mui/material";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import Footer from "./Footer";
import { sendStartupEvent } from "./telemetry/HoneycombService";
import ReducingOnCallLoad from "./Blog/ReducingOnCallLoad";

const OverallApp = styled.div`
  background-color: white;
`;

const ContentBody = styled.div`
  margin-top: 60px;
`;

sendStartupEvent();

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: ["Lato", "Roboto Condensed", "Roboto"].join(","),
    },
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <OverallApp>
            <TopBar />
            <ContentBody>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/blog/reducing-on-call-load"
                  element={<ReducingOnCallLoad />}
                />
              </Routes>
            </ContentBody>
            <Footer />
          </OverallApp>
        </QueryParamProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const container = document.getElementById("root");

const root = createRoot(container);
root.render(<App />);
