import * as React from "react";
import { DateTime } from "luxon";
import { Alert } from "../alert/Alert";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import AlertTiming from "../alert/AlertTiming";

interface AlertDetailsTableProps {
  alerts: Alert[];
  alertTimingFilters?: AlertTiming[];
}

interface AlertDetailsTableRow {
  timing: string;
  createdOn: DateTime;
  description?: string;
  id: string;
}

const cellStyle = {
  paddingLeft: "25px",
};

const headerStyle = {
  paddingLeft: "25px",
  backgroundColor: "lightgrey",
};

const columns = [
  {
    field: "timing",
    title: "Timing",
    headerStyle,
    cellStyle,
    render: (row: AlertDetailsTableRow) => (
      <Typography
        variant="body2"
        data-testid={`alert-details-row-timing-${row.id}`}
      >
        {row.timing}
      </Typography>
    ),
  },
  {
    field: "createdOn",
    title: "Created",
    headerStyle,
    cellStyle,
    render: (row: AlertDetailsTableRow) => (
      <Typography
        variant="body2"
        data-testid={`alert-details-row-created-${row.id}`}
      >
        {row.createdOn.toLocaleString(DateTime.DATETIME_MED)}
      </Typography>
    ),
  },
  {
    field: "description",
    title: "Description",
    headerStyle,
    cellStyle,
    render: (row: AlertDetailsTableRow) => (
      <Typography
        variant="body2"
        data-testid={`alert-details-row-description-${row.id}`}
      >
        {row.description}
      </Typography>
    ),
  },
];

const AlertDetailsTableTitle = styled(Typography)``;

const AlertDetailsTable = ({
  alerts,
  alertTimingFilters,
}: AlertDetailsTableProps) => {
  const filteredAlerts = alertTimingFilters
    ? alerts.filter((alert) => alertTimingFilters.includes(alert.timing))
    : alerts;

  const tableRows = filteredAlerts.map(
    (alert, idx) =>
      ({
        timing: alert.timing,
        createdOn: alert.createdOn,
        description: alert.description || "",
        id: alert.id || idx,
      } as AlertDetailsTableRow)
  );

  const pageSize = 10;

  return (
    <MaterialTable
      columns={columns}
      data={tableRows}
      title={
        <AlertDetailsTableTitle
          variant="h5"
          data-testid="alert-details-table-title"
        >
          Alert details
        </AlertDetailsTableTitle>
      }
      style={{ boxShadow: "none" }}
      options={{
        exportAllData: true,
        exportMenu: [
          {
            label: "Export CSV",
            exportFunc: (cols, rows) => ExportCsv(cols, rows, "oncalm-alerts"),
          },
          {
            label: "Export PDF",
            exportFunc: (cols, rows) => ExportPdf(cols, rows, "oncalm-alerts"),
          },
        ],
        padding: "dense",
        pageSize,
        pageSizeOptions: [10, 20, 40],
      }}
    />
  );
};

export default AlertDetailsTable;
