import * as React from "react";
import { FormEvent } from "react";
import styled from "@emotion/styled";
import { Alert } from "../alert/Alert";
import {
  fetchAlerts,
  fetchServices,
  PagerDutyService,
} from "../PagerDuty/PagerDutyService";
import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "react-select";
import { DateTime, Duration } from "luxon";

type PagerDutyApiViewProps = {
  onAlertsLoaded: (alerts: Alert[]) => void;
};

type ServiceSelectOption = {
  label: string;
  value: string;
};

const ApiKeySection = styled("div")`
  margin-top: 15px;
  margin-left: 15px;
`;

const ApiKeyTextField = styled(TextField)`
  min-width: 250px;
`;

const ApiKeySubmitButton = styled(Button)`
  margin-left: 20px;
  margin-top: 5px;
`;

const ServiceSection = styled("div")`
  margin-top: 15px;
  margin-left: 15px;
`;

const ServiceSelectLabel = styled(Typography)`
  margin-bottom: 15px;
`;

const ServiceSubmitButton = styled(Button)`
  margin-top: 15px;
`;

const PagerDutyApiView = ({ onAlertsLoaded }: PagerDutyApiViewProps) => {
  const [apiKey, setApiKey] = React.useState<string>();

  const [services, setServices] = React.useState<PagerDutyService[]>(null);
  const [serviceSelectOptions, setServiceSelectOptions] = React.useState<
    ServiceSelectOption[]
  >([]);

  const [selectedServiceId, setSelectedServiceId] =
    React.useState<string>(null);

  const onApiKeySubmit = async (event: FormEvent) => {
    event.preventDefault();

    const allServices = await fetchServices(apiKey);
    setServices(allServices);

    const selectionOptions: ServiceSelectOption[] = allServices.map(
      (service) => ({
        value: service.id,
        label: service.name,
      })
    );
    setServiceSelectOptions(selectionOptions);
  };

  const apiKeyHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const apiKeyValue = e.currentTarget.value;

    setApiKey(apiKeyValue.trim());
  };

  const onServiceSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const selectedService = services.find(
      (service) => service.id === selectedServiceId
    );

    const oneWeekAgo = DateTime.now().minus(Duration.fromObject({ weeks: 1 }));
    const now = DateTime.now();

    const alerts = await fetchAlerts(selectedService, oneWeekAgo, now, apiKey);

    onAlertsLoaded(alerts);
  };

  const serviceHandleChange = (selectedOption: ServiceSelectOption) => {
    setSelectedServiceId(selectedOption.value);
  };

  return (
    <div>
      <ApiKeySection>
        <form onSubmit={onApiKeySubmit}>
          <ApiKeyTextField
            type="password"
            label="PagerDuty API key"
            onChange={apiKeyHandleChange}
            data-testid="pager-duty-api-key-input"
          />

          <ApiKeySubmitButton
            variant="contained"
            type="submit"
            data-testid="pager-duty-api-key-submit-button"
            size="large"
          >
            Connect to PagerDuty
          </ApiKeySubmitButton>
        </form>
      </ApiKeySection>
      {services && services.length > 0 && (
        <ServiceSection>
          <form onSubmit={onServiceSubmit}>
            <label htmlFor="service-select">
              <ServiceSelectLabel>
                Select your team's PagerDuty service:
              </ServiceSelectLabel>
            </label>
            <Select
              options={serviceSelectOptions}
              id="service-select"
              onChange={serviceHandleChange}
              placeholder="Select service..."
            />

            <ServiceSubmitButton
              variant="contained"
              type="submit"
              data-testid="pager-duty-service-submit-button"
              size="large"
            >
              Load alerts from PagerDuty
            </ServiceSubmitButton>
          </form>
        </ServiceSection>
      )}
    </div>
  );
};

export default PagerDutyApiView;
