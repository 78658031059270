import * as React from "react";
import Button from "@mui/material/Button";
import { useState } from "react";
import { sendUploadEvent } from "./telemetry/HoneycombService";

interface CsvUploadFormProps {
  onFileUpload: (File) => void;
}

const CsvUploadForm = ({ onFileUpload }: CsvUploadFormProps) => {
  const [file, setFile] = useState<File>();

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    onFileUpload(file);

    sendUploadEvent();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          onChange={handleChange}
          data-testid="csv-file-upload"
        />
        <Button
          variant="contained"
          type="submit"
          data-testid="csv-upload-button"
        >
          Upload
        </Button>
      </form>
    </div>
  );
};

export default CsvUploadForm;
