import * as React from "react";
import HomePageTitle from "./HomePageTitle";
import AlertView from "../AlertView";
import HomePageSteps from "./HomPageSteps";
import HomePageQuestions from "./HomePageQuestions";
import { StringParam, useQueryParam } from "use-query-params";

const HomePage = () => {
  const [location, setLocation] = useQueryParam("loc", StringParam);
  const [zoneName, setZoneName] = useQueryParam("tz", StringParam);

  const scrollToLocation = () => {
    if (location === "measure") {
      const id = "measure";
      /* Find matching element by id */
      const element = document.getElementById(id);

      if (element) {
        const headerOffset = 80;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
        });
      }
    }
  };

  React.useEffect(() => {
    scrollToLocation();
  }, []);

  scrollToLocation();

  return (
    <div>
      <HomePageTitle />
      <HomePageSteps />
      <AlertView zoneName={zoneName} />
      <HomePageQuestions />
    </div>
  );
};

export default HomePage;
