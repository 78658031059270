import * as React from "react";
import { Typography } from "@mui/material";

type BlogPostParagraphProps = {
  children: React.ReactNode;
};

const BlogPostParagraph = ({ children }: BlogPostParagraphProps) => (
  <Typography css={{ marginBottom: "20px" }}>{children}</Typography>
);

export default BlogPostParagraph;
