// Libhoney is the Honeycomb SDK for sending telemetry Events to Honeycomb's API
// Docs for Libhoney live at https://docs.honeycomb.io/getting-data-in/javascript/libhoney/
// Here's our Honeycomb SDK configuration
import Libhoney from "libhoney";
import { v4 as uuidv4 } from "uuid";

const sessionId = uuidv4();
const apiKey: string = process.env.HONEYCOMB_API_KEY;

const hny = new Libhoney({
  writeKey: apiKey,
  dataset: "oncalm-frontend-events",
});

export const sendEvent = (eventType: string) =>
  sendHoneycombEvent({ event_type: eventType });

export const sendStartupEvent = () => sendEvent("startup");

export const sendUploadEvent = () => sendEvent("upload");

export const sendCsvProcessEvent = (totalAlertCount: number) => {
  sendHoneycombEvent({
    event_type: "csv_process",
    total_alert_count: totalAlertCount,
  });
};

export const sendCsvProcessError = (errorMessage: string) => {
  sendHoneycombEvent({
    event_type: "csv_error",
    error: errorMessage,
  });
};

export const sendApiProcessEvent = (totalAlertCount: number) => {
  sendHoneycombEvent({
    event_type: "api_process",
    total_alert_count: totalAlertCount,
  });
};

export const sendChatGptProcessEvent = (chatGptMessage: string) => {
  sendHoneycombEvent({
    event_type: "chatgpt_process",
    chatgpt_message: chatGptMessage,
  });
};

export const sendChatGptProcessError = (errorMessage: string) => {
  sendHoneycombEvent({
    event_type: "chatgpt_process_error",
    error: errorMessage,
  });
};

const sendHoneycombEvent = (eventData) => {
  if (apiKey && apiKey.length > 0) {
    // Let's send an event each time the app starts up
    const ev = hny.newEvent();
    eventData.sessionId = sessionId;
    ev.add(eventData);
    // Uncomment this line to add a user agent field to your Events
    // ev.addField("user_agent", typeof window && window.navigator && window.navigator.userAgent );
    ev.send();
  }
};
