import {
  sendChatGptProcessError,
  sendChatGptProcessEvent,
} from "../telemetry/HoneycombService";

export const fetchImpactAnalysisMessage = async (
  daytimeCount: number,
  nighttimeCount: number
): Promise<string> => {
  const requestBody = {
    daytime: daytimeCount,
    nighttime: nighttimeCount,
  };

  try {
    const response = await fetch("/impact", {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    const responseJson = await response.json();

    const message = responseJson.message;

    sendChatGptProcessEvent(message);

    return message;
  } catch (e) {
    console.error("Failed to fetch impact", e);

    sendChatGptProcessError(e.message);
  }
};
