import * as React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
// @ts-ignore
import screenshot from "../screen-shot.png";

const TitleText = styled(Typography)`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const DescriptionGrid = styled(Grid)`
  margin: 10px;
`;

const DescriptionSection = styled.div`
  margin-bottom: 20px;
  display: inline-block;
`;

const DescriptionText = styled(Typography)`
  text-align: left;
`;

const Emphasis = styled.span`
  font-weight: bold;
`;

const MainTitleSection = styled.div`
  background-color: #1976d2;
  color: white;
  padding-top: 15px;
  padding-bottom: 30px;
  margin-bottom: 20px;
`;

const ScreenshotImage = styled.img`
  max-width: 400px;
`;

const HomePageTitle = () => {
  return (
    <MainTitleSection>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <TitleText variant="h2">
            Tame your on-call pages with OnCalm
          </TitleText>
        </Grid>

        <DescriptionGrid item md={4} xs={12}>
          <DescriptionSection>
            <DescriptionText variant="h6">
              High page load in your on-call rotation has many negative
              consequences
            </DescriptionText>
          </DescriptionSection>

          <DescriptionSection>
            <DescriptionText>
              <Emphasis>Increased team member attrition</Emphasis> from
              interrupted nights and weekends
            </DescriptionText>
            <DescriptionText>
              <Emphasis>Difficulties recruiting</Emphasis> new members to the
              team
            </DescriptionText>
            <DescriptionText>
              <Emphasis>Slower response time to real issues</Emphasis> since
              they get lost in the noise
            </DescriptionText>
          </DescriptionSection>

          <DescriptionSection>
            <DescriptionText variant="h6">
              How much is your on-call rotation impacting your team?
            </DescriptionText>
            <DescriptionText>
              Quickly measure your team's on-call load by using OnCalm
            </DescriptionText>
          </DescriptionSection>
        </DescriptionGrid>

        <DescriptionGrid item md={4} xs={12}>
          <ScreenshotImage src={screenshot} />
        </DescriptionGrid>
      </Grid>
    </MainTitleSection>
  );
};

export default HomePageTitle;
