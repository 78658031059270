import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Typography } from "@mui/material";
import { sendEvent } from "../telemetry/HoneycombService";
import BlogPostSectionHeading from "./BlogPostSectionHeading";
import BlogPostParagraph from "./BlogPostParagraph";
import BlogPost from "./BlogPost";
import BlogPostTitle from "./BlogPostTitle";
import post from "bundle-text:./reducing-on-call-load.md";
// @ts-ignore
import nightTimeAlert from "./night-time-alarm.png";

const ReducingOnCallLoad = () => {
  sendEvent("reducing-on-call-load-startup");

  return (
    <BlogPost>
      <BlogPostTitle>5 tips for reducing on-call load</BlogPostTitle>
      <img
        src={nightTimeAlert}
        css={{ maxWidth: "100%", maxHeight: "400px", marginBottom: "15px" }}
      />
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <BlogPostTitle>{props.children}</BlogPostTitle>
          ),
          h2: ({ node, ...props }) => (
            <BlogPostSectionHeading>{props.children}</BlogPostSectionHeading>
          ),
          p: ({ node, ...props }) => (
            <BlogPostParagraph>{props.children}</BlogPostParagraph>
          ),
          li: ({ node, ...props }) => (
            <li>
              <Typography>{props.children}</Typography>
            </li>
          ),
        }}
      >
        {post}
      </ReactMarkdown>
    </BlogPost>
  );
};

export default ReducingOnCallLoad;
