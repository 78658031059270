import * as React from "react";
import { Grid, Link, Typography } from "@mui/material";
import styled from "@emotion/styled";

const HomePageQuestionsContainer = styled(Grid)`
  padding: 20px 10px 20px 10px;
  background-color: #1976d2;
  color: white;
`;

const HomePageQuestionContainer = styled.div`
  margin-bottom: 30px;
`;

const HomePageQuestionTitle = styled(Typography)`
  margin-bottom: 5px;
`;

const HomePageQuestionLink = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const HomePageQuestions = () => {
  return (
    <HomePageQuestionsContainer
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item md={6} xs={12}>
        <HomePageQuestionContainer>
          <HomePageQuestionTitle variant="h6">
            Why a separate app for calculating on-call load?
          </HomePageQuestionTitle>
          <Typography>
            Many paging apps do a great job of the mechanics of on-call - paging
            when alerts happen, managing on-call rotations, etc. - but have
            minimal capabilities for measuring and tracking the impact on the
            engineers in your on-call rotation. OnCalm aims to bridge that gap,
            regardless of what paging system you use currently.
          </Typography>
        </HomePageQuestionContainer>

        <HomePageQuestionContainer>
          <HomePageQuestionTitle variant="h6" id="paging-supported">
            What paging apps does OnCalm support?
          </HomePageQuestionTitle>
          <Typography>
            Currently OnCalm supports{" "}
            <HomePageQuestionLink href="https://support.pagerduty.com/docs/reports#download-an-incidents-report-csv">
              PagerDuty CSV files
            </HomePageQuestionLink>{" "}
            and the{" "}
            <HomePageQuestionLink href="https://support.pagerduty.com/docs/api-access-keys#section-generate-a-general-access-rest-api-key">
              PagerDuty API
            </HomePageQuestionLink>
            . Support for additional paging systems is in the works, please
            email support@oncalm.dev if you're interested in support for a
            specific paging system.
          </Typography>
        </HomePageQuestionContainer>

        <HomePageQuestionContainer>
          <HomePageQuestionTitle variant="h6">
            How do I get a PagerDuty API key?
          </HomePageQuestionTitle>
          <Typography>
            In the PagerDuty UI, go to the{" "}
            <HomePageQuestionLink href="https://support.pagerduty.com/docs/api-access-keys#section-generate-a-general-access-rest-api-key">
              Integrations {"->"} API Access Keys page and create a token
            </HomePageQuestionLink>{" "}
            (can be a read-only token). Then enter that API token in the "API"
            tab above to easily fetch your alerts from the PagerDuty API.
          </Typography>
        </HomePageQuestionContainer>

        <HomePageQuestionContainer>
          <HomePageQuestionTitle variant="h6">
            What is a unique disruption?
          </HomePageQuestionTitle>
          <Typography>
            A unique disruption is a page that is at least 15 minutes apart from
            another page. During major outages it’s common for many pages to
            trigger at once, which can skew the overall page count as the
            on-call engineer is likely already engaged in fixing the issue.
            Pages spread out are much more disruptive - for example, pages every
            hour at night that keep waking up the on-call engineer.
          </Typography>
        </HomePageQuestionContainer>

        <HomePageQuestionContainer>
          <HomePageQuestionTitle variant="h6">
            I want to drill down into my off-hours and night-time pages, can I
            filter by alert timing?
          </HomePageQuestionTitle>
          <Typography>
            Yes! Click on the entries in the alert-timing column (Off-hours,
            Night-time, etc.) in the On-call Analysis table to filter the
            detailed alert list by timing.
          </Typography>
        </HomePageQuestionContainer>

        <HomePageQuestionContainer>
          <HomePageQuestionTitle variant="h6">
            Can I download the alert details list for offline processing?
          </HomePageQuestionTitle>
          <Typography>
            Yes! Click the download icon next to the search box in the Alert
            Details list to download the alert details in CSV or PDF format.
          </Typography>
        </HomePageQuestionContainer>
      </Grid>
    </HomePageQuestionsContainer>
  );
};

export default HomePageQuestions;
