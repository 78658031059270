import * as React from "react";
import { Grid, styled } from "@mui/material";

type BlogPostProps = {
  children: React.ReactNode;
};

const BlogPostWrapper = styled("div")`
  width: 100%;
  background-color: #1976d2;
  color: #232629;
`;

const BlogPostContent = styled("div")`
  background-color: white;
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
`;

const BlogPost = ({ children }: BlogPostProps) => (
  <BlogPostWrapper>
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xl={6} lg={8} md={10} xs={12}>
        <BlogPostContent>{children}</BlogPostContent>
      </Grid>
    </Grid>
  </BlogPostWrapper>
);

export default BlogPost;
