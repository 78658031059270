import * as React from "react";
import { Alert, styled, Tooltip, tooltipClasses } from "@mui/material";

interface AlertCountStatusProps {
  count: number;
  goodThreshold: number;
  warningThreshold: number;
  goodTooltip: string;
  warningTooltip: string;
  unsustainableTooltip: string;
}

type LightTooltipProps = {
  children: React.ReactElement;
  title: string;
  className?: string;
};

const LightTooltip = styled(
  ({ className, children, title }: LightTooltipProps) => (
    <Tooltip
      classes={{ popper: className }}
      children={children}
      title={title}
      placement="right"
      arrow
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}));

const AlertCountStatus = ({
  count,
  goodThreshold,
  warningThreshold,
  goodTooltip,
  warningTooltip,
  unsustainableTooltip,
}: AlertCountStatusProps) => {
  let label;
  let severity;
  let tooltip;

  if (count <= goodThreshold) {
    label = "Good";
    severity = "success";
    tooltip = goodTooltip;
  } else if (count <= warningThreshold) {
    label = "Danger";
    severity = "warning";
    tooltip = warningTooltip;
  } else {
    label = "Unsustainable";
    severity = "error";
    tooltip = unsustainableTooltip;
  }

  return (
    <LightTooltip title={tooltip}>
      <Alert variant="filled" severity={severity}>
        {label}
      </Alert>
    </LightTooltip>
  );
};

export default AlertCountStatus;
