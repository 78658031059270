import * as React from "react";
import { Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";

interface HomePageStepProps {
  title: string;
  body: string;
}

const HomePageStepPaper = styled(Paper)`
  min-height: 100px;
  padding: 20px;
`;

const HomePageStepTitle = styled(Typography)`
  margin-bottom: 10px;
`;

const HomePageStep = ({ title, body }: HomePageStepProps) => {
  return (
    <HomePageStepPaper elevation={0}>
      <HomePageStepTitle variant="h6">{title}</HomePageStepTitle>
      <Typography variant="body1">{body}</Typography>
    </HomePageStepPaper>
  );
};

export default HomePageStep;
