import { Alert } from "../alert/Alert";
import { parse } from "csv-parse/lib/sync";
import { DateTime } from "luxon";

const parseCsv = (csvText: string): Alert[] => {
  const rows = parse(csvText, {
    columns: true,
    skip_empty_lines: true,
  });

  const isPDAdvanced = rows.some((row) => row["created_on"]);
  const isPDSimple = rows.some((row) => row["Opened On"]);

  if (isPDAdvanced) {
    const alerts = rows.map(({ id, description, created_on, resolved_on }) => {
      return {
        id: id,
        description: description,
        createdOn: DateTime.fromISO(created_on),
        resolvedOn: resolved_on ? DateTime.fromISO(resolved_on) : null,
      } as Alert;
    });

    return alerts;
  } else if (isPDSimple) {
    const alerts = rows.map((row) => {
      return {
        id: row["#"],
        createdOn: DateTime.fromISO(row["Opened On"]),
        resolvedOn: row["Resolved On"]
          ? DateTime.fromISO(row["Resolved On"])
          : null,
      } as Alert;
    });

    return alerts;
  } else {
    return [];
  }
};

export { parseCsv };
